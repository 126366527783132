import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import APCategoryBtns from '../../components/preowned/APCategoryBtns'

// markup
const APMain = () => {
  const data = useStaticQuery(
    graphql`
      query queryAPMain {
        products: allStrapiProduct(
          filter: { brand: { eq: "Audemars Piguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Audemars Watches for Sale'}
      canonical={'/fine-watches/audemars-piguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Audemars Piguet watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/audemars-piguet/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-audemars-header.png"
                alt="Pre-Owned Certified Used Audemars Piguet Watches Header"
                aria-label="Used Audemars Piguet Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Audemars Piguet Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AUDEMARS PIGUET WATCHES AT
              GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED AUDEMARS PIGUET WATCHES WITH
              GRAY AND SONS JEWELERS
            </h3>
            <p>
              Established in 1875, Audemars Piguet is one of the most renowned watch brands in the
              luxury market. In fact, thanks to the brand’s mastery of high-end mechanical watches,
              AP is part of the so-called Holy Trinity of Watches, along with Patek Philippe and
              Vacheron Constantin.
              <br />
              <br />
              The most famous Audemars Piguet watch, by far, is the Royal Oak, which was created by
              legendary designer Gerald Genta and released to the world in 1972. Pre-owned Audemars
              Piguet Royal Oak watches are incredibly sought-after in the secondary market, and
              often sell for higher-than-retail prices.
              <br />
              <br />
              Other coveted used Audemars Piguet watches include Royal Oak Offshore models, which
              are bigger and bolder versions of the original Royal Oak. What’s more, secondhand and
              vintage complicated Audemars Piguet watches are especially valuable in the vintage
              market as they are rare collectors items
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Audemars Piguet Watches</b>
              <br />
              <br />
              Some of the most popular used AP watches in the market include Royal Oak, Royal Oak
              Chronograph, Royal Oak Offshore, Royal Oak Offshore Diver, as well as an assortment of
              classic, complicated, and vintage Audemars Piguet watches.
              <br />
              <br />
              Whether you’re looking to buy or sell Audemars Piguet watches, Gray &amp; Sons can
              help. To buy a used Audemars Piguet watch, reach out to one of our
              <a href="/chat-with-rich/">Decision makers </a>
              right now and make an offer.
              <br />
              <br />
              On the other hand, if you’ve recently asked yourself, “Where can I sell my used
              Audemars Piguet watch?” let our buying division
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com </a> you secure the
              best cash offer.
              <br />
              <br />
              Popular Pre-Owned Used AP Watch References and Models:
              <br />
              <ul>
                <li>Pre-Owned Royal Oak “Jumbo” references: 5402, 14802, 15002, 15202, 16202</li>
                <li>
                  Pre-Owned Royal Oak Self-Winding references: 15300, 15400, 15450, 15500, 15550
                </li>
                <li>Pre-Owned Royal Oak 36mm references: 4100, 14332, 14486, 14700, 14790</li>
                <li>
                  Pre-Owned Royal Oak Chronograph, Royal Oak Perpetual Calendar, Royal Oak
                  Tourbillon, Royal Oak Concept
                </li>
              </ul>
              <br />
              <br />
              <br />
              <br />
              <b>Gray &amp; Sons is the Best Source for Buying and Selling Pre-Owned Watches </b>
              <br />
              <br />
              Given that pre-owned Audemars Piguet watches are so valuable, it’s important to buy
              them from a trustworthy source such as Gray and Sons.
              <br />
              <br />
              Gray and Sons has been buying and selling pre-owned luxury watches, including used
              Audemars Piguet watches, for over 40 years. It’s no wonder that so many of our
              satisfied customers have nominated Gray and Sons as the number one buyer and seller of
              pre-owned Audemars Piguet watches.
              <br />
              <br />
              Any used AP watch for sale on our website,
              <a href="/">www.grayandsons.com, </a> is in stock, and ready to ship immediately.
              Alternatively, you can drop by the Gray and Sons boutique located near the upscale Bal
              Harbour Shops in the Miami area. We are open six days a week and our team of watch
              experts is ready to guide you through our large collection of secondhand Audemars
              Piguet watches for sale.
              <br />
              <br />
              WEBSITE: <a href="/">www.grayandsons.com </a>
              &amp;
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com </a>
              <br />
              CALL: 305-770-6955. <br />
              TEXT: Vika at 786-266-4763 for a quick response. <br />
              EMAIL: sell@grayandsons.com <br /> <br />
              Gray and Sons Jewelers specializes in Pre-Owned, Estate,
              <br />
              Vintage, Antique, Heirloom, Used Watches &anp; Jewelry
              <br />
              <br />
              We Buy, Sell Trade, Consign and Repair Used and Pre-Owned Watches and Jewelry
              <br />
              <br />
              Our customers say; "There are no better certified pre-owned Watch Specialists &amp;
              Jewelers than Gray and Sons Jewelers in all of Florida and the US"
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/audemars-piguet/">
                <button>SHOP AUDEMARS PIGUET WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK AUDEMARS PIGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <APCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default APMain
